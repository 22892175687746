:root
{
  --yellow: #F5C32C;
    --orange : #FCA61F;
    --black : #242D49;
    --gray : #788097;
    --blueCard : #DDF8FE;
    --purple: rgb(238 210 255);
    --boxShadow : 0px 19px 60px rgb(0 0 0 / 8%);
    --orangeCard: rgba(252, 166, 31, 0.45);
    --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App{
    padding: 0.5rem 3.5rem;
    overflow: hidden;
    color: var(--black);    
}

.button {
    background: #7B3F00;
    border-radius: 34px;
    border: none;
    color: white;
    font-size: 16px;
    padding: 11px 26px 11px 26px;
  }
  .head-txt {
    margin: 8% 2% 2% 9%;
    font-size: 2rem;
    font-weight: normal;
    color: #7B3F00;
  }
  .button:hover {
    cursor: pointer;
  }
  @media screen and (max-width: 480px) {
    .App {
      padding: 0.5rem 1rem;
    }
    .head-txt {
      font-size: 25px;
      margin: 4% 1% 1% 6%;
    }
  }

  

  