.Intro {
  display: flex;
  height: 77vh;
  margin-top: 6rem;
}

/* left side styling */
.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  gap: 2rem;
}
.i-name {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}
.i-name > :nth-child(1) {
  color: #7B3F00;
  font-weight: bolder;
  font-size: 3rem;
}
button {
  background: #7B3F00;
}
.i-name > :nth-child(2) {
  color: #7B3F00;
  font-weight: bold;
  font-size: 1rem;
}
.i-name > :nth-child(3) {
  font-weight: 100;
  font-size: 14px;
  color: var(--gray);
  margin-top: 10px;
}
.i-button {
  width: 6rem;
  height: 2rem;
}
.i-icons {
  margin-top: 3rem;
  display: flex;
  gap: 0rem;
}
.i-icons > * {
  transform: scale(0.5);
}
.i-icons > *:hover {
  cursor: pointer;
}
.cake{
  width:40%;
}

/* right side styling */
.i-right {
  flex: 1;
  position: relative;
}
.i-right > * {
  position: absolute;
  z-index: 1;
}
.i-right > :nth-child(1) {
  left: 35%;
  top: -35%;
}


/* blur */
.blur {
  position: absolute;
  width: 22rem;
  height: 14rem;
  border-radius: 50%;
  background: #edd0ff;
  filter: blur(212px);
  z-index: -9;
  top: -18%;
  left: 56%;
  filter: blur(72px);
}

@media screen and (max-width: 480px) {
  .Intro {
    flex-direction: column;
    gap: 0rem;
    height: 54rem;
    margin-top: 0rem;
  }
  .i-right > :nth-child(1) {
    left: 16%;
    top: -20%;
  }
  .cake{
    width:80%;
  }
  .i-right {
    transform: scale(0.8);
    left: -3rem;
  }
  .i-right .blur {
    display: none;
  }
  .floating-div:nth-of-type(1){
    top: -7rem !important;
  }
}
